import { IAnalytics, IdentifyOptions } from './IAnalytics';
import attrify from 'attrify';
import * as Cookies from 'js-cookie';
import { User } from '../../api';

type ReadyListener = () => void;

export class Segment implements IAnalytics {
  private isReady: boolean = false;
  private readyListeners: ReadyListener[] = [];
  private traits: any = undefined;

  constructor() {
    attrify({
      domain:
        process.env.REACT_APP_STAGE === 'staging'
          ? 'localhost'
          : '.incodocs.com',
    });
    this.waitForReady();
  }

  /**
   * Waits for segment to be ready
   * Not using segments native ready
   */
  private waitForReady() {
    if (this.segment) {
      this.isReady = true;
      this.readyListeners.forEach((listener) => listener());
    } else {
      setTimeout(() => this.waitForReady, 50);
    }
  }

  private onReady(listener: ReadyListener) {
    if (this.isReady) {
      listener();
    } else {
      this.readyListeners.push(listener);
    }
  }

  private get segment() {
    return (window as any).analytics;
  }

  private get intercom() {
    return (window as any).Intercom;
  }

  private get hasIntercom() {
    return (window as any).Intercom !== undefined;
  }

  private get hasProfitwell() {
    return (window as any).profitwell !== undefined;
  }

  private get profitwell() {
    return (window as any).profitwell;
  }

  private get hasClarity() {
    return (window as any).profitwell !== undefined;
  }

  private get clarity() {
    return (window as any).clarity;
  }

  private callIdentify = (id: string, user: any, options: IdentifyOptions) => {
    if (options.restartIncercom) {
      this.resetIntercom(user);
    }
    this.resetProfitwell(user);
    this.segment.identify(
      options.useAnonymousId ? this.segment.user().anonymousId() : id,
      user
    );

    if (this.hasClarity) this.clarity?.("identify", user?.email);
  };

  private resetProfitwell(user: any) {
    if (!this.hasProfitwell) return;
    this.profitwell('start', {
      user_id: user.billingId,
    });
  }

  private resetIntercom(user: any) {
    if (!this.hasIntercom) return;
    const intercomSettings = {
      app_id: (window as any).analytics._integrations.Intercom.options.appId,
      ...(window as any).intercomSettings,
      ...user,
    };
    this.intercom('shutdown');
    this.intercom('boot', intercomSettings);
  }

  identify = (
    currentUser: User,
    updatedTraits: any = {},
    options: any = {
      restartIntercom: true,
      useAnonymousId: false,
    }
  ) => {
    const self = this;
    const user = {
      billingId: currentUser.company?.accountBilling?.billingId,
      companyId: currentUser.company?.id,
      userId: currentUser.id,
      email: currentUser.email,
      firstName: currentUser.attributes.firstName,
      lastName: currentUser.attributes.lastName,
      createdDocument: currentUser.attributes.onboarding.createdDocument,
      completedNewUserSurvey:
        currentUser.attributes.onboarding.completedNewUserSurvey,
      createdAt: currentUser.createdAt,
      plan: currentUser.company?.plan,
      country: currentUser?.company?.country?.name,
      utm_campaign: this.getCookie('utm_campaign'),
      utm_source: this.getCookie('utm_source'),
      utm_medium: this.getCookie('utm_medium'),
      utm_term: this.getCookie('utm_term'),
      utm_content: this.getCookie('utm_content'),
      referral_url: this.getCookie('referrer'),
      inspectlet: `https://www.inspectlet.com/dashboard/captures/749564472?daterange=12months&tags=%7B%22paneopen%22%3A%22basic%22%2C%22tagslist%22%3A%5B%7B%22tag%22%3A%22userId%22%2C%22value%22%3A%22${currentUser.id}%22%7D%5D%2C%22operator%22%3A%22and%22%7D`,
      ...updatedTraits,
    };
    this.traits = {
      billingId: user.billingId,
      userId: user.userId,
      companyId: user.companyId,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      country: user.country,
      plan: user.plan,
    };
    if (this.isReady) {
      this.callIdentify(currentUser.id, user, options);
    } else {
      this.onReady(() => {
        self.callIdentify(currentUser.id, user, options);
      });
    }
  };

  private getCookie = (name: string) => {
    let cookie = Cookies.get(`initial_${name}`);
    if (cookie && cookie !== 'null') return cookie;
    cookie = Cookies.get(name);
    if (cookie && cookie !== 'null') return cookie;
    return undefined;
  };

  track = (event: string, properties: any = {}) => {
    this.segment.track(event, properties, { traits: this.traits });
  };

  page = (name?: string, properties: any = {}) => {
    this.segment.page(name, properties);
  };

  reset = () => {
    this.segment.reset();
    if (this.hasIntercom) this.intercom('shutdown');
  };
}
