import { ApolloLink } from "@apollo/client";
import { omitDeep } from "../../lib/util";

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, "__typename");
  }
  return forward(operation).map((data) => {
    return data;
  });
});

export default cleanTypenameLink;
