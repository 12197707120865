let config = {
  auth: {
    cognito: {
      userPoolId: '',
      region: '',
      userPoolWebClientId: '',
    },
  },
  mountPath: '/users',
};

if (
  process.env.REACT_APP_STAGE === 'development' ||
  process.env.REACT_APP_STAGE === undefined
) {
  const developmentConfig = require('./environments/development').default;
  config = {
    ...config,
    ...developmentConfig,
  };
}

if (process.env.REACT_APP_STAGE === 'production') {
  const productionConfig = require('./environments/production').default;
  config = {
    ...config,
    ...productionConfig,
  };
}

if (process.env.REACT_APP_STAGE === 'staging') {
  const stagingConfig = require('./environments/staging').default;
  config = {
    ...config,
    ...stagingConfig,
  };
}

export default config;
