import { IAnalytics } from './IAnalytics';
import { Segment } from './Segment';
import { Stub } from './Stub';

let analytics: IAnalytics;

if (
  process.env.REACT_APP_STAGE === 'staging' ||
  process.env.REACT_APP_STAGE === 'production'
) {
  analytics = new Segment();
} else {
  analytics = new Stub();
}

export default analytics;
