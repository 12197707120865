import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import client from './api/client';
import { ErrorBoundary } from './modules/Application/components';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';
import { BrowserBanner } from './modules/Application/components/browser-detection/browser-banner';

if (process.env.REACT_APP_STAGE === 'production') {
  Sentry.init({
    dsn: 'https://8f5e9c7034e84ba9ae25ebf959c446f9@sentry.io/1453759',
  });
}

ReactDOM.render(
  <ErrorBoundary>
    <ApolloHooksProvider client={client}>
      <BrowserBanner />
      <App />
    </ApolloHooksProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
