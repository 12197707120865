import gql from 'graphql-tag';

export const CompanyFragment = gql`
  fragment CompanyFragment on Company {
    id
    name
    trialEndAt
    trialDaysLeft
    inTrial
    isDowngrading
    paidAt
    paymentRequired
    awaitingInvoice
    plan
    accountBilling {
      billingId
    }
    preferences {
      autoNumber
      headings
    }
    attributes {
      brn
      customerType
      customerTypeOther
    }
    stripeConnect {
      accountId
      status
      paymentMethods {
        card
      }
    }
    country {
      name
    }
  }
`;
