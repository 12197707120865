import { useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { User, UserFragment } from "../../../api";

import { useSessionStore } from "./useSessionStore";
import { OperationVariables, QueryOptions } from "@apollo/client";
const USER_QUERY = gql`
  {
    viewer {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

interface Data {
  viewer: User;
}

interface Options {
  useCache?: boolean;
  delay?: boolean;
}

const defaultOptions: Options = {
  useCache: true,
  delay: false,
};

export function useUser(options: Options = defaultOptions) {
  const args = Object.assign({}, defaultOptions, options);
  const { state, setState } = useSessionStore();
  const client = useApolloClient();

  async function getUser(variables: Partial<QueryOptions<any>> = {}) {
    return client
      .query<Data>({
        query: USER_QUERY,
        fetchPolicy: args.useCache ? "cache-first" : "network-only",
        ...variables,
      })
      .then(({ loading, data, errors }) => {
        return new Promise<User>((resolve, reject) => {
          if (!loading && !errors) {
            setState({ ...state, user: data.viewer });
            resolve(data.viewer);
          }
        });
      });
  }

  useEffect(() => {
    if (!args.delay) {
      getUser();
    }
  }, []);

  return { user: state.user, getUser };
}
