import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { Link } from 'react-router-dom';
import { Button, Header, ScrollContainer, FlexBox } from '@incodocs/react/ui';

interface State {
  error?: any | null;
  eventId?: string | null;
}

export class ErrorBoundary extends Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.error && this.state.eventId) {
      //render fallback UI
      return (
        <FlexBox column>
          <Header>
            <Header.Start>
              <a href="/">
                <Button secondaryBlue>Back to Home</Button>
              </a>
            </Header.Start>
            <Header.Middle />
            <Header.End />
          </Header>
          <FlexBox flex="1" justifyContent="center" alignItems="center">
            <div className="mt--2">
              <h1 className="type--2 type--medium type--s100 type--center">
                Looks like something went wrong.
              </h1>
              <p className="type--1 type--default type--s075 type--center mt--1_5">
                The error has been recorded and the team will resolve the
                problem.
              </p>
              <p className="type--1 type--default type--s075 type--center">
                To help the team resolve the problem, please click the report
                feedback button to provide more details.
              </p>
              <FlexBox className="mt--2" justifyContent="center">
                <a href="/">
                  <Button secondaryBlue className="mr--1">
                    Back to Home
                  </Button>
                </a>
                <Button
                  base
                  onClick={() =>
                    Sentry.showReportDialog({
                      eventId: this.state.eventId as string,
                    })
                  }
                >
                  Report feedback
                </Button>
              </FlexBox>
            </div>
          </FlexBox>
        </FlexBox>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}
