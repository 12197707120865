import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client';
import introspectionQueryResultData from './fragmentTypes.json';
const possibleTypes = {};

introspectionQueryResultData.__schema.types.forEach((supertype) => {
  if (supertype.possibleTypes) {
    possibleTypes[supertype.name] = supertype.possibleTypes.map(
      (subtype) => subtype.name
    );
  }
});
export default new InMemoryCache({
  possibleTypes,
  dataIdFromObject: (object) => {
    if (object.__typename === 'Template') {
      return null; // disable caching for templates
    }
    return defaultDataIdFromObject(object);
  },
});
