const config = {
  auth: {
    cognito: {
      userPoolId: 'us-west-2_JraUhkjSJ',
      userPoolWebClientId: '10qa3cvn5upibva3668q9q9red',
      region: 'us-west-2',
    },
  },
};

export default config;
