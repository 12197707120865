import React, { FunctionComponent, useEffect } from 'react';
import { Spinner } from '@incodocs/react/ui';
import '@incodocs/react/ui/imported-styles';
import 'react-tippy/dist/tippy.css';
import './styles/index.scss';

import '@incodocs/documents/template-view/stlyes/layout/index.scss';
import '@incodocs/documents/template-view/stlyes/print/index.scss';
import '@incodocs/documents/template-view/stlyes/edit/index.scss';
import 'react-contexify/dist/ReactContexify.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import { useOnLoad } from './modules/User/hooks/useOnLoad';
import Routes from './Routes';
import { toggleIntercom } from './lib/intercom';
import history from './lib/history';
import analytics from './lib/analytics';

const App: FunctionComponent = () => {
  const { loading, user } = useOnLoad();

  useEffect(() => {
    history.listen((location, action) => {
      analytics.page('Web App');
    });
  }, []);

  return (
    <>
      {loading && <Spinner delay={200} />}
      {!loading && <Routes />}
    </>
  );
};

export default App;
