import styled, { StyledProps, StyledComponent } from 'styled-components';
import {
  compose,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
  boxShadow,
  BoxShadowProps,
  background,
  BackgroundProps,
  border,
  BorderProps,
} from 'styled-system';

export type BoxProps = StyledComponent<
  'div',
  SpaceProps &
    LayoutProps &
    FlexboxProps &
    PositionProps &
    BoxShadowProps &
    BackgroundProps &
    BorderProps
>;

export const Box = styled.div<
  SpaceProps &
    LayoutProps &
    FlexboxProps &
    PositionProps &
    BoxShadowProps &
    BackgroundProps &
    BorderProps
>`
  ${compose(space, layout, flexbox, position, boxShadow, background, border)}
`;
