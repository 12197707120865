const supportedBrowsers = [
  /chrome|chromium|crios/i, // google chrome
  /edg/i, // microsoft edge chromium version
];

export function isBrowserSupported(): boolean {
  return supportedBrowsers.some((browser) =>
    navigator.userAgent.match(browser)
  );
}
