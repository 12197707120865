import { ApolloClient, ApolloLink, split } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import Auth from "@aws-amplify/auth";
import cache from "./cache";
import cleanTypenameLink from "./cleanTypenameFieldLink";

const wsLink = new WebSocketLink({
  uri: `${window.location.protocol === "https:" ? "wss:" : "ws:"}//${
    window.location.host
  }/api/graphql`,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      try {
        const session = await Auth.currentSession();
        return { idtoken: session.getIdToken().getJwtToken() };
      } catch (err) {
        return {};
      }
    },
  },
});

const apiLink = new BatchHttpLink({
  uri: process.env.REACT_APP_API_URL || "/api/graphql",
  batchMax: 2,
});

const authLink = setContext(
  async (operation) => {
    try {
      const session = await Auth.currentSession();
      return { headers: { idtoken: session.getIdToken().getJwtToken() } };
    } catch (err) {
      return {};
    }
  }
  // Auth.currentSession().then(session => {
  //   return {
  //     headers: { idtoken: session.getIdToken().getJwtToken() }
  //   };
  // })
);

export const client = new ApolloClient({
  cache,
  link: split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    ApolloLink.from([cleanTypenameLink, authLink, apiLink])
  ),
});

export default client;
