import gql from 'graphql-tag';
import { CompanyFragment } from './Company';

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    email
    role
    company {
      ...CompanyFragment
    }
    enabledFeatures {
      name
      enabled
    }
    attributes {
      firstName
      lastName
      onboarding {
        emailVerified
        createdDocument
        completedNewUserSurvey
        intent
        achievements
      }
      signature {
        name
        type
        isSigned
        dataUrl
        canvas {
          height
          width
        }
      }
    }
    createdAt
  }
  ${CompanyFragment}
`;
