import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@incodocs/react/ui';
import { isBrowserSupported } from './is-browser-supported';
import { Box } from '../../../Shipments/components/box';

const Banner = styled.div`
  padding: 1rem;
  border-bottom: 1px solid rgba(3, 27, 78, 0.12);

  z-index: 9999;
  .btn {
    z-index: 9999;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BrowserBannerProps {
  forceShowBanner?: boolean;
}

export const BrowserBanner: FunctionComponent<BrowserBannerProps> = ({
  forceShowBanner,
}) => {
  const [showBanner, setShowBanner] = useState(() => !isBrowserSupported());

  if (!showBanner && !forceShowBanner) return null;

  return (
    <Banner>
      <p className="type--s100 type--medium type--1 type--center">
        Your browser is not fully supported, you may encounter issues using
        IncoDocs if you continue to use it. For the best experience please use
        one of the following browsers:
      </p>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <a
          className="type--p100 type--medium type--1"
          href="https://www.google.com.au/chrome/"
        >
          Google Chrome
        </a>
        <a
          className="type--p100 type--medium type--1"
          href="https://www.microsoft.com/en-us/edge"
        >
          Microsoft Edge
        </a>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          linkSecondary
          onClick={() => setShowBanner(false)}
          icon="close"
          iconPosition="right"
        >
          close
        </Button>
      </Box>
    </Banner>
  );
};
