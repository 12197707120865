import React, { FunctionComponent, lazy, Suspense } from 'react';
import './styles/index.scss';
import { Router, Route, Switch } from 'react-router-dom';
import { Spinner } from '@incodocs/react/ui';
import history from './lib/history';

const DocsRouter = lazy(() => import('./modules/Docs/DocsRouter'));
const UserRoutes = lazy(() => import('./modules/User/UserRouter'));
const SalesRoutes = lazy(() => import('./modules/Sales/SalesRouter'));
const ProductRoutes = lazy(() => import('./modules/Products/ProductsRouter'));
const ContactRoutes = lazy(() => import('./modules/Contacts/ContactsRouter'));
const NotFound = lazy(() => import('./modules/Application/pages/NotFound'));
const NoWorkspace = lazy(() =>
  import('./modules/Application/pages/NoWorkspace')
);
const ShareRoutes = lazy(() => import('./modules/Share/ShareRouter'));
const CounterSignRoutes = lazy(() =>
  import('./modules/CounterSign/CounterSignRouter')
);
const FilesRoutes = lazy(() => import('./modules/Files/FilesRouter'));
const SavedDetailsRoutes = lazy(() =>
  import('./modules/SavedDetails/SavedDetailsRouter')
);
const SettingsRoutes = lazy(() => import('./modules/Settings/SettingsRouter'));
const ShipmentsRoutes = lazy(() =>
  import('./modules/Shipments/ShipmentsRouter')
);
const DashboardRoutes = lazy(() =>
  import('./modules/Dashboard/DashboardRouter')
);
const LandedCostRoutes = lazy(() =>
  import('./modules/LandedCost/LandedCostRouter')
);
const IntegrationRoutes = lazy(() =>
  import('./modules/Integrations/IntegrationsRouter')
);
const PartnershipsRoutes = lazy(() =>
  import('./modules/partnerships/partnerships-router')
);

const Routes: FunctionComponent = () => {
  return (
    <Suspense fallback={<Spinner delay={200} />}>
      <Router history={history}>
        <Switch>
          <Route path="/file" component={FilesRoutes} />
          <Route path="/share" component={ShareRoutes} />
          <Route path="/counter-sign" component={CounterSignRoutes} />
          <Route path="/users" component={UserRoutes} />
          <Route path="/products" component={ProductRoutes} />
          <Route path="/contacts" component={ContactRoutes} />
          <Route path="/details" component={SavedDetailsRoutes} />
          <Route path="/settings" component={SettingsRoutes} />
          <Route path="/shipments" component={ShipmentsRoutes} />
          <Route path="/sales" component={SalesRoutes} />
          <Route path="/landed-cost" component={LandedCostRoutes} />
          <Route path="/integrations" component={IntegrationRoutes} />
          <Route path="/partners" component={PartnershipsRoutes} />
          <Route path="/dashboard" component={DashboardRoutes} />
          <Route path="/no-workspace" component={NoWorkspace} />
          <Route path="/" component={DocsRouter} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default Routes;
