import { Store } from '@incodocs/react/controller';
import { User } from '../../../api';

export interface SessionState {
  signedIn: boolean;
  user: User;
  loading: boolean;
  emailVerified: boolean;
  onboardingComplete: boolean;
  skipEmailVerification?: boolean;
  preferences: { [key: string]: any };
}

export const sessionStore = new Store<SessionState>({
  onboardingComplete: false,
  signedIn: false,
  loading: true,
  emailVerified: false,
  preferences: {}
} as any);
