import { useState, useEffect } from 'react';
import Auth from '@aws-amplify/auth';
import { configure } from '../config/configureCognito'; // configure cognito
import { useSessionStore } from './useSessionStore';
import { useUser } from './useUser';
import { emailVerfiy } from '../../../modules/User/Routes';
import analytics from '../../../lib/analytics';

export function useOnLoad() {
  const { setState, state } = useSessionStore();
  const { getUser } = useUser({ delay: true });
  useEffect(() => {
    configure();
    Auth.currentSession()
      .then(async (session) => {
        const user = await getUser();
        const cognitoAttributes = await Auth.userAttributes(
          await Auth.currentAuthenticatedUser()
        );
        const emailAttribute = cognitoAttributes.find(
          (attr) => attr.getName() === 'email_verified'
        );
        let emailVerified = false;
        if (emailAttribute)
          emailVerified = emailAttribute.getValue() === 'true';

        setState({
          ...state,
          user,
          signedIn: true,
          loading: false,
          onboardingComplete: user.attributes.onboarding.completedNewUserSurvey,
          emailVerified,
        } as any);
        analytics.identify(user);
        analytics.track('signed_in');
      })
      .catch((e) => {
        analytics.reset();
        setState({ ...state, signedIn: false, loading: false });
      });
  }, []);
  return { loading: state.loading, user: state.user };
}
